// API

// export const API = 'http://localhost/toulouse-symfony/public/api'
//export const API = 'http://toulouse-symfony-ronaldrlrj12350764.codeanyapp.com/public/api'
export const API = 'https://api.weemat.fr'
export const SERVER = 'https://api.weemat.fr'

export const PUBLIC_CLIENT_ID = 'ca_G9wpvWSD2VUETDZp08l6JDBskwxfIrB3' // PROD
// export const PUBLIC_CLIENT_ID = 'ca_G9wpTYeBWRmhWB2HRIYdL4b0aq4qEJJ2' // TEST

//export const STRIPE_REDIRECT_URI = 'https://toulouse-symfony-ronaldrlrj12350764.codeanyapp.com/public/fournisseur/accept-stripe'
export const STRIPE_REDIRECT_URI = 'https://stripe.weemat.fr/accept-stripe'

// USER
export const IS_USER = 'is_user'
export const SIGNIN = 'sign_in'
export const SIGNOUT = 'sign_out'
export const PASSWORDRESET = 'password_reset'
export const TOKEN = 'token'
export const STRIPE_CONNECTION = 'stripe_connected'

// COMMAND
export const HTTP_ON_PROCESS = 'http_onprocess'
export const COMMAND_AVAILABLE = 'commands_available'
export const COMMAND_ACCEPTED = 'command_accepted'