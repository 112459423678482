import { HTTP_ON_PROCESS, COMMAND_AVAILABLE,COMMAND_ACCEPTED } from "../Type"

const initState = {
    httpReqOnProcess:false,
    commandsList:[]
}

export const commandReducer = (state=initState,action) => {
    if(action.type === HTTP_ON_PROCESS) {
        return {
            ...initState,
            httpReqOnProcess:true
        }
    }

    if(action.type === COMMAND_AVAILABLE) {
        console.log(action.commands)
        return {
            ...initState,
            httpReqOnProcess:false,
            commandsList:action.commands
        }
    }

    if(action.type === COMMAND_ACCEPTED) {
        let newList = []
        state.commandsList.map((c,idx)=>{
            if(action.command !== c.id) {
                return newList.push(c)
            }
        })
        return {
            ...initState,
            httpReqOnProcess:false,
            commandsList:newList
        }
    }

    return initState;
}