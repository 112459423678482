

import { API, TOKEN, SIGNOUT, SIGNIN, STRIPE_CONNECTION } from "../Type";
import Axios from "axios";

const localS = localStorage
// CHECK USER SIGNED
export const checkUser = () =>(dispatch) =>{
    let token = localS.getItem(TOKEN)
    if(token !== null) {
        let header = {
            'wee-app-fournisseur-token':token
        }
        dispatch({
            type:'user_req_on_process'
        })
        Axios.get(API+'/fournisseur',{headers:header}).then(
            res=> {
                dispatch({
                    type:'fournisseurId',
                    payload : {
                        'fournisseurId':res.data.id,
                        'stripeConnectedId':res.data.stripeConnectId,
                        'boutique':res.data.nom
                    }
                })
               dispatch(SignIn(token))
            }
        ).catch(
            err=>{
                console.log(err.response)
                // if(err.response.status === 403) {
                //     dispatch(signOut())
                // }
            }
        )
        
    } else {
        return dispatch(signOut())
    }
}

// USER SIGNOUT
export const signOut = () => {
    localS.removeItem(TOKEN)
    return {
        type:SIGNOUT
    } 
}
// USER SIGNIN
export const SignIn = (token) => {
    localS.setItem(TOKEN,token)
    return {
        type:SIGNIN,
        payload:{
            token:token
        }
    }
}
