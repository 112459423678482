import React,{ Component } from 'react'
import { connect } from 'react-redux'
import Header from './Header';
import { Redirect } from 'react-router-dom'
import '../style/Espace.css';
import { getAvailableCommand, acceptCommand, getAttentPaymentCommand, getAttentLivraisonCommand, getLastCommand } from '../action/commandAction';
import Command from './Command'
import { checkUser } from '../action/userAction';
import StripeConfirmation from './StripeConfirmation';


class Espace extends Component {
    
    constructor(props){
        super(props)
       // this.props.checkUser()
        this.state={
            currentPage:'available'
        }
        this.props.availableCommands()
        // if(this.props.user.userSigned){
        //     this.methoadByRoot(this.props.match.params.list)
        // }
    }


    componentDidUpdate(prevProps) {
        if (this.props.match.params.list !== prevProps.match.params.list) {
            this.methoadByRoot(this.props.match.params.list)
        }
    }
    

    methoadByRoot(list) {
        if(list === 'attent-payment') {
            this.setState({
                currentPage:'waitingpayment'
            })
            return this.props.waitingPayment()
        }

        if(list === 'attent-livraison') {
            this.setState({
                currentPage:'waitingdelivery'
            })
            return this.props.waitingDelivery()
        }

        if(list === 'dernier-command') {
            this.setState({
                currentPage:'lastcommand'
            })
            return this.props.lastCommands()
        }

        this.setState({
            currentPage:'available'
        })
        return this.props.availableCommands()
    }

    content(){

        if(this.props.command.httpReqOnProcess){
            return <div className="loader"></div>
        } else {
            console.log(this.props.command)
            if(this.props.command.commandsList.length === 0) {
                return (<div className='row mt-5'><p>Il n'y a pas de commands disponible</p></div>)
            }else {
                return (<div className='row mt-5'>
                {
                    this.props.command.commandsList.map((cmd,idx)=>{
                        return <Command key={idx} command={cmd} CurrentPage={this.state.currentPage}></Command>
                    })
                }
                </div>)
            }
        }
    }

    display() {
        if(!this.props.user.userSigned)
        {
            return <Redirect to='/'/>
        }
        //console.log(this.props.user.httpReqOnProcess,this.props.user.stripeConnected)
        if(this.props.user.httpReqOnProcess){
            return (<div className="loader"></div>)
        } else {
            if(!this.props.user.stripeConnected){
                return <StripeConfirmation userId={this.props.user.fournisseurId}></StripeConfirmation>
            } else {
                return (
                    <div className=" body">
                        {/* Header */}
                        <Header></Header>
                        {/* Elements */}
                        <div className='container'>
                            {this.content()}
                        </div>
                    </div>
                )
            }
        }
    }
    

    render() {
        return (this.display())        
    }
}

const mapToState = (state) => ({
    user:state.user,
    command:state.command
})


const mapToAction = (dispatch) => ({
        availableCommands : () => dispatch(getAvailableCommand()),
        waitingPayment:() => dispatch(getAttentPaymentCommand()),
        waitingDelivery:() => dispatch(getAttentLivraisonCommand()),
        lastCommands:() => dispatch(getLastCommand()),
        //checkUser:() => dispatch(checkUser())
})

export default connect(mapToState,mapToAction)(Espace)