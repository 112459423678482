import { SIGNIN, SIGNOUT, STRIPE_CONNECTION } from "../Type";


const initState = {
    userSigned:false,
    token:'',
    stripeConnected:false,
    fournisseurId:0,
    httpReqOnProcess:false,
    boutique:''
}

export const userReducer = (state=initState,action) => {
    
    if(action.type === SIGNIN) { // IF USER SIGNIN SUCCESS
        state.userSigned = true
        state.token = action.payload.token
    }

    if(action.type === SIGNOUT) { // IF USER SIGNOUT SUCCESS
        state.userSigned = false
        state.token = ''
    }

    if(action.type === STRIPE_CONNECTION) {
        state.stripeConnected = true
    }

    if(action.type === 'fournisseurId') {
        state.fournisseurId = action.payload.fournisseurId
        state.boutique = action.payload.boutique
        state.httpReqOnProcess = false
        if(action.payload.stripeConnectedId !== null){
            state.stripeConnected = true
        }
    }

    if(action.type === 'user_req_on_process') {
        state.httpReqOnProcess = true
    }

    return state
}