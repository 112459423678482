// import React,{useState} from 'react'
import { connect } from 'react-redux'

import '../style/command-info.css'
import { acceptCommand, validateCommand } from '../action/commandAction'

// export default function Command(props) {
//     const 
//     const [httpOnProcess,setProcess] = useState(false)
//     const dispatch = useDispatch()
//     const 

// }



import React, { Component } from 'react';
import { SERVER } from '../Type';

export class Command extends Component {
    
    constructor(props){
        super(props)
        this.state = {
            httpOnProcess:false,
            date: new Date(props.command.DateWant),
            bonCommand:SERVER+'/bondecommand/pdf/'+this.props.command.id
        }

    }
    setProcess(process){
        this.setState({
            httpOnProcess:process
        })
    }
    accept = () => {
        this.setProcess(true)
        this.props.acceptCommand(this.props.command.id,this.setProcess.bind(this))
    }

    validate = () => {
        this.setProcess(true)
        this.props.validateCommand(this.props.command.id,this.setProcess.bind(this))
    }

    Button = () => {
        if(this.state.httpOnProcess) {
            return (
                <button className='btn voir-plus mx-auto text-center' disabled>
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </button>
            )
        } else {
            if(this.props.CurrentPage === 'available'){
                return(
                    <div className='row'>
                        <div className='col-12'>
                            <button onClick={this.accept} className='btn voir-plus mx-auto my-3 text-center'>Accept</button>
                        </div>
                    </div>
                )
            }
            if(this.props.CurrentPage === 'waitingdelivery'){
                return(
                    <div className='row'>
                        <div className='col-6'>
                            <button className='btn voir-plus mx-auto my-3 text-center'>
                                <a href={this.state.bonCommand} >Bon de command</a>
                            </button>
                        </div>
                        <div className='col-6'>
                            <button onClick={this.validate} className='btn voir-plus mx-auto my-3 text-center'>Validé</button>
                        </div>
                    </div>
                )
            }
        }
    }

    render() {
        return (
            <div className='command-info col-5 mx-3 mt-5'>
                <h3 className='text-center'>{this.state.date.toLocaleDateString('fr-FR')}</h3>
                <hr />
                <div className='row'>
                    <div className='col-6'>TotalPrix :</div>
                    <div className='col-6'>€ {this.props.command.PrixTotalFournisseur}</div>
                </div>
    
                <div className='row'>
                    <div className='col-6'>Nbr de Produits :</div>
                    <div className='col-6'>{this.props.command.commandDetails.length}</div>
                </div>
    
                <div className='row'>
                    <div className='col-6'>L'heure de livraison :</div>
                    <div className='col-6'><b>{this.props.command.timeWant}</b></div>
                </div>
                <hr />
                <h5 className='text-center'>Adresse Livraison</h5>
                <div className='row mb-3'>
                    <div className='col-2'></div>                
                    <div className='col-8'>
                        {this.props.command.deliveryAddresse.firstname} {this.props.command.deliveryAddresse.lastname}<br />
                        {this.props.command.deliveryAddresse.adresse}< br />
                        {this.props.command.deliveryAddresse.postcode} - {this.props.command.deliveryAddresse.city}< br />
                    </div>
                </div>
                <hr />
                {
                    this.props.command.commandDetails.map((cmd,idx)=>{
                        return(
                            <div className='row' key={idx}>
                                <div className='col-6'>{cmd.product.name}</div>
                                <div className='col-3'>{cmd.quantity}</div>
                                <div className='col-3'>{cmd.product.orderMethod}</div>
                            </div>
                        )
                    })
                }
                <hr />
                {this.Button()}
            </div>
        )
    }
}

const mapToAction = (dispatch) => ({
    acceptCommand : (cmId,cb) => dispatch(acceptCommand(cmId,cb)),
    validateCommand : (cmId,cb) => dispatch(validateCommand(cmId,cb))
})

export default connect(null,mapToAction)(Command);
