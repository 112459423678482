import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createStore,applyMiddleware,compose } from 'redux'
import { rootReducer } from './reducer';
import {Provider} from 'react-redux'

import thunx from 'redux-thunk'

const middleWare = [thunx]

const store = createStore(rootReducer,applyMiddleware(...middleWare))

ReactDOM.render(
 <Provider store={store}>
<App />
</Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
