import { API, TOKEN, HTTP_ON_PROCESS, COMMAND_AVAILABLE, COMMAND_ACCEPTED } from "../Type";
import Axios from "axios";

export const reqOnProcess = () => (dispatch) =>{

       return dispatch({
            'type':HTTP_ON_PROCESS
        })
}


export const acceptCommand = (cmdId,cb) => (dispatch) => {
    let localS = localStorage
    const token = localS.getItem(TOKEN)
    //setTimeout(()=>{cb(false)},500)
    let header = {
        'wee-app-fournisseur-token':token
    }
    return Axios.get(API+'/fournisseur/accept-command/'+cmdId,{headers:header})
    .then(
        res=>{
            cb(false)
            return dispatch({
                'type':COMMAND_ACCEPTED,
                'command':cmdId
            })
        }
    ).catch() 
    
}

export const validateCommand = (cmdId,cb) => (dispatch) =>{
    let localS = localStorage
    const token = localS.getItem(TOKEN)

    let header = {
        'wee-app-fournisseur-token':token
    }
    return Axios.get(API+'/fournisseur/valid-command/'+cmdId,{headers:header})
    .then(
        res=>{
            cb(false)
            return dispatch({
                'type':COMMAND_ACCEPTED,
                'command':cmdId
            })
        }
    ).catch() 
}

// getAvailable Commands
export const getAvailableCommand = () => (dispatch) => {

    let localS = localStorage
    const token = localS.getItem(TOKEN)

    let header = {
            'wee-app-fournisseur-token':token
    }
    dispatch(reqOnProcess())
    return Axios.get(API+'/fournisseur/available-commands',{headers:header})
    .then(
        res=>{
            dispatch({
                'type':COMMAND_AVAILABLE,
                'commands':res.data
            })
        }
    ).catch()   
    
}


// getAttentPayment Commands
export const getAttentPaymentCommand = () => (dispatch) => {
    
    let localS = localStorage
    const token = localS.getItem(TOKEN)

    let header = {
        'wee-app-fournisseur-token':token
    }
    dispatch(reqOnProcess())
    return Axios.get(API+'/fournisseur/attent-payment-commands',{headers:header})
    .then(
        res=>{
            return dispatch({
                'type':COMMAND_AVAILABLE,
                'commands':res.data
            })
        }
    ).catch(
        err=>{
            console.log(err)
        }
    )
}

// getAttentLivraison Commands
export const getAttentLivraisonCommand = () => (dispatch) => {
    
    let localS = localStorage
    const token = localS.getItem(TOKEN)

    let header = {
        'wee-app-fournisseur-token':token
    }
    
    dispatch(reqOnProcess())
    return Axios.get(API+'/fournisseur/attent-livraison-commands',{headers:header})
    .then(
        res=>{
            return dispatch({
                'type':COMMAND_AVAILABLE,
                'commands':res.data
            })
        }
    ).catch(
        err=>{
            console.log(err)
        }
    )
}

// getDernier Commands
export const getLastCommand = () => (dispatch) => {
    
    let localS = localStorage
    const token = localS.getItem(TOKEN)

    let header = {
        'wee-app-fournisseur-token':token
    }
    
    dispatch(reqOnProcess())
    return Axios.get(API+'/fournisseur/dernier-commands',{headers:header})
        .then(
            res=>{
                console.log(res.data)
                return dispatch({
                    'type':COMMAND_AVAILABLE,
                    'commands':res.data
                })
            }
        ).catch(
            err=>{
                console.log(err)
            }
        )
}