import React from 'react';
import { useDispatch } from 'react-redux'
import '../style/stripe.css'
import { PUBLIC_CLIENT_ID, STRIPE_REDIRECT_URI } from '../Type';
import { signOut } from '../action/userAction';
import { Redirect } from 'react-router-dom'

const StripeConfirmation = (props) => {

    const Link = 'https://connect.stripe.com/oauth/authorize?response_type=code&client_id='+PUBLIC_CLIENT_ID+'&scope=read_write&redirect_uri='+STRIPE_REDIRECT_URI+'&state='+props.userId

    const dis = useDispatch()

    const SignOut = ()=>{
        dis(signOut())
        return <Redirect to='/'/>
    }

    return (
        <div className='confirmationPage mt-5'>
            <div className='content mx-auto'>
                <p className='text-center'>Vous devez connecter votre comptre stipe pour pouvoire accesdez le site</p>
                <a className='acceptStripe btn btn-success mr-3 ml-5' href={Link}>Accept</a>
                <a className='deconnect btn btn-danger' href='/' onClick={SignOut}>Déconnect</a>
            </div>
        </div>
    );
}

export default StripeConfirmation;
