import React,{useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { API, TOKEN } from "../Type";
import Axios from "axios";
import { SignIn, checkUser } from '../action/userAction';
import { Redirect } from "react-router-dom";

const loginSpace = {
    height:'75vh',
    marginTop:'15vh',
    borderRadius:'15px',
    boxShadow: "0 1px 6px 0 rgba(32, 33, 36, .28)",
    // background:'#2E3847',
}


const inputField = {
    height:'35px',
    boxShadow: '0px -1px 15px -6px rgba(181,201,230,1)',
    borderRadius:'15px',
     background:'#fff'
}

const input = {
    border:'none',
    width:'100%',
    height:'100%',
    outline:'none',
    // background:'#2E3847',
    fontSize:'14px',
    letterSpacing:'1.2px'
}

const submitBtn = {
    border:'0.6px solid rgba(181,201,230,0.4)',
    boxShadow: '0px -1px 15px -6px rgba(181,201,230,1)',
}

/// USER SIGN IN METHOD
const signIn = (username,password) => {
    let endpoint = API+'/fournisseur/sign-in'

    let formData = new FormData()
    formData.append('username',username)
    formData.append('password',password)

    return Axios.post(endpoint,formData,{
        headers:{
            'wee-app-login-fournisseur':'wee-app-login-fournisseur'
        }
    })
    
}

const heading = {
    color:'#221D23'
}

export default function Login({match}) {

    const [username,setUsername] = useState("")
    const [password,setPassword] = useState("")
    const [httpOnProcess,setProcess] = useState(false)
    const [errors,setErrors] = useState([])

    const userConnected = useSelector((state)=>{return state.user.userSigned})

    const dis = useDispatch()

    const submit = () => {

        setProcess(true) 
        signIn(username,password)
        .then(res=>{
            setProcess(false)
            console.log(res.data)
            if(res.data.token !== undefined){
                dis(SignIn(res.data.token))
                dis(checkUser())
            }          
           // return <Redirect to='/available'></Redirect>
        })
        .catch(err=>{
            setProcess(false)
            setErrors([...errors,'Username ou mdp n\'est pas bon'])
        })
    }

    const checkSignIn = () =>{
        setErrors([])
        if(username.length === 0){
            setErrors([...errors,'Username doit pas être vide'])
        }

        if(password.length === 0){
            setErrors([...errors,'Mot De Pass doit pas être vide'])
        }
        if(password.length !== 0 && username.length !== 0) {
            submit()
        }
    }

    const Button = () => {
        if(httpOnProcess) {
            return (
                <button className='btn col-4 mx-auto' style={submitBtn} disabled>
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </button>
            )
        } else {
            return(
                <button className='btn col-4 mx-auto' style={submitBtn} onClick={checkSignIn}>
                    Login
                </button>
            )
        }
    }

    const Errors = () => {
        if(errors.length !== 0) {
            return(
                <ul>
                    {errors.map((e,idx)=>{
                        return(<li key={idx}>{e}</li>)
                    })}
                </ul>
            )
        } else {
            return (<div></div>)
        }
    }

    const redirect = (userConnected) => {
        if(userConnected) {
            if(match.path === '/'){
               // console.log(localStorage.getItem(TOKEN))
               return <Redirect to='/available' />
            }else{
               return <Redirect to={match.path} />
            }
        } 
    }
    
    return (
        <div className="col-6 mx-auto pt-5" style={loginSpace}>
            {console.log('login page')}
            {Errors()}
            {redirect(userConnected)}
            {/* userName */}
            <div className="row ml-5 pl-5 mt-5">
                <h5 className='col-8' style={heading}>Username</h5>
                <div className='col-8' style={inputField}>
                    <input style={input} name='username' value={username} onChange={ event => setUsername(event.target.value)}/>
                </div>
            </div>
            {/* passWord */}
            <div className="row ml-5 pl-5 mt-4">
                <h5 className='col-8'>Password</h5>
                <div className='col-8' style={inputField}>
                    <input type='password' style={input} name='password'  value={password}
                     onChange={event => setPassword(event.target.value)}/>
                </div>
            </div>
            {/* Button */}
            <div className='mt-5 row '>
                {Button()}
            </div>
        </div>
    )
}
