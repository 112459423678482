import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import '../style/Header.css'

import { NavLink,Redirect } from 'react-router-dom'
import { signOut } from '../action/userAction';

export default function Header() {

    const dis = useDispatch()

    const boutique = useSelector((state)=>{return state.user.boutique})

    const SignOut = ()=>{
        dis(signOut())
        return(<Redirect to='/'/>)
    }

    const links = [
        {
            title:'Commandes à prendre',
            link:'/available'
        },
        {
            title:'Commandes prises en attente paiement',
            link:'/attent-payment'
        },
        {
            title:'Commandes payées à livrer',
            link:'/attent-livraison'
        },
        {
            title:'Commandes livrées',
            link:'/dernier-command'
        }
    ]


    return (
        <nav className="navigationBar row px-4 py-3">
            <div className="col-8">
                {
                    links.map((l,idx)=>{
                        return(<NavLink  key={idx} className="link p-2 ml-3" activeClassName="activate" to={l.link}>{l.title}</NavLink>)
                    })
                }
            </div>
            <div className="col-2">
                <div class="dropdown">
                    <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {boutique}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item p-2" href="/" onClick={SignOut}>Déconnect</a>
                        <a class="dropdown-item p-2" target='_blank' href='https://dashboard.stripe.com/payments'>Accéder Dashboard de stripe</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}
