import React from 'react'

import { Switch,Route } from 'react-router-dom'

import Espace from './component/Espace'

import Login from './component/Login'

import StripeConfirmation from './component/StripeConfirmation'

export default function Routes(props) {
    
    return (
        <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path='/stripe-confirmation' component={StripeConfirmation}/>
            <Route exact path='/:list' component={Espace} />
        </Switch>
    )
}
