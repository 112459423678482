import React from "react";
import "./App.css";
import { HashRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import { useDispatch } from 'react-redux'
import { checkUser} from "./action/userAction";
import Login from "./component/Login";


function App() {
  const dispatch = useDispatch()
  dispatch(checkUser())
  return (
    // <Provider store={store}>
      <Router>
        {/* <div>
          
        </div> */}
        {/* {redirect(userConnected)} */}
        <Routes></Routes>
      </Router>
    // </Provider>
  );
}

export default App;
